import { html, css, LitElement } from 'lit-element';

import { FW_SEARCH_REQUEST } from "./fw-search.js";
/*
 * FuquaWorld Search Input 
 */
export class FwSearchInput extends LitElement {

  static get properties() {
    return {
        redirect: { Boolean },
        query: { String },
        base: { type: String }
    };  
  }

  connectedCallback() {
	super.connectedCallback();
  }
  
  constructor() {
    super();
    this.redirect = false;
    this.query = "";
    this.base = "";
  }
  
  emitEvent(e) {
    if (this.redirect) {
        location.href = this.base + "/fw/search/" + encodeURI(document.getElementById("querystring").value );
    } else {
        let event = new CustomEvent( 
            FW_SEARCH_REQUEST, { 'bubbles': true, 'composed': true, 'detail': { query: document.getElementById("querystring").value } } );
        document.dispatchEvent(event);
    }
  }
  
  render() { return html`<div class="large-search-container"> 
                            <input @keydown=${ (e) => { 
                                if (e.isComposing || e.keyCode === 229) {
                                    return;
                                } 
                                if (e.keyCode === 13) {
                                    this.emitEvent(e);
                                }
                            }} id="querystring" class="search large-search" type="text" tabindex="1" placeholder="Find what you're looking for." value="${this.query}"> 
                            <button @click=${this.emitEvent} name="search" class="fw--btn" tabindex="2">Search <i class="fas fa-search"></i></button> 
                        </div>
                    `;
  }
  
  createRenderRoot() {
	return this;
  }

}
if (!customElements.get('fw-search-input')) customElements.define('fw-search-input', FwSearchInput);